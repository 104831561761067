<template>
  <Layout
    :is-home="isHome"
    :has-context-menu="!!isValidPlace && showContextMenu"
    :show-sticky-in-app="showStickyinApp"
    :bandPlacement="bandPlacement"
    @scrolling="handleScroll">
    <ContextMenu v-if="isValidPlace && showContextMenu" />
    <div
      ref="stickySentinel"
      style="visibility: hidden"></div>
    <div class="vp">
      <Sheet
        v-if="fetchedData.placements && fetchedData.placements.full_cover && fetchedData.placements.full_cover.html"
        class="is_full_cover"
        :placement="fetchedData.placements.full_cover" />
      <Sheet
        v-if="fetchedData.placements && fetchedData.placements.cover && fetchedData.placements.cover.html"
        :placement="fetchedData.placements.cover" />      
    </div>
    <RichRelevance
      v-if="fetchedData.vendors?.RichRelevance?.hasRichRelevance && !isModal && hasAcceptedCookies.PERSONALIZATION && !isCartLoading"
      :pageType="isHome ? PAGE_TYPES.HOME_PAGE : PAGE_TYPES.STATIC_PAGE" />
    <GoToTop />
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ContextMenu from 'Components/Layout/Header/ContextMenu/ContextMenu.vue';
import GoToTop from 'CommonComponents/GoToTop/GoToTop.vue';
import Layout from 'Components/Layout/Layout.vue';
import Sheet from 'CommonComponents/Sheets/Render.vue';
import RichRelevance from 'Components/Vendors/RichRelevance/RichRelevance.vue';

import initCarousels from 'CommonUtils/siteManager/carousel';
import PAGE_TYPES from 'CommonUtils/schemas/pageTypes';
import PLACE_TYPES from 'Utils/placeTypes';
import UtilsAnalytics from 'CommonUtils/analytics';
import ScriptUtils from 'CommonUtils/addScript';

import NavigatorDetect from 'CommonMixins/navigatorDetect';

import { useSSRContext } from 'vue';

const SCROLL_DIRECTION = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
});

export default {
  name: 'Static',
    components: {
    ContextMenu,
    GoToTop,
    Layout,
    RichRelevance,
    Sheet
  },
  mixins: [NavigatorDetect],
  provide: function () {
    return {
      assetsBase: this.fetchedData?._assets_base,
      crossSellingType: undefined,
      hidePromotions: false,
      homeDomain: this.fetchedData?._home_domain,
      internals: this.fetchedData?._internals,
      isPLP: true,
      isScrolling: () => this.isScrolling,
      isCampaignsSiteMode: this.fetchedData?.is_campaigns_site_mode,
      richRelevance: this.fetchedData?.vendors?.RichRelevance,
    };
  },
  data() {
    return {
      PAGE_TYPES,
      scrollDirection: null,
      SCROLL_DIRECTION,
      iObserver: null,
      stickySentinel: null,
      isStickySentinelVisible: true,
    };
  },
  computed: {
    ...mapGetters('page', {
      isApp: 'isApp',
      isEciAppFocus: 'isEciAppFocus',
      isNewApp: 'isNewApp',
      placeType: 'getPlaceType',
      isModal: 'isModal',
      hasAcceptedCookies: 'hasAcceptedCookies',
      isHome: 'isHome',
      hasVisibleContextMenu: 'hasVisibleContextMenu',
    }),
    ...mapGetters('cart', {
      isCartLoading: 'getLoadingStatus',
    }),
    isValidPlace() {
      return this.placeType != PLACE_TYPES.NOT_PLACE;
    },
    showStickyinApp() {
      if (!this.isApp) return true;
      if (this.isStickySentinelVisible) return true;
      return this.scrollDirection === SCROLL_DIRECTION.UP || !this.scrollDirection;
    },
    bandPlacement() {
      return this.fetchedData && this.fetchedData.placements && this.fetchedData.placements.band
        ? this.fetchedData.placements.band
        : null;
    },
    showContextMenu() {
      if(!this.isApp && !this.isNewApp && !this.isEciAppFocus && this.hasVisibleContextMenu) {
        return true;
      }
      return (this.isApp && !this.isNewApp) || this.isEciAppFocus;
    }
  },
  beforeCreate() {
    this.fetchedData = import.meta.env.SSR ? useSSRContext() : window.__MOONSHINE_STATE__;
  },
  created() {
    const { code, privacy_link, designers } = this.fetchedData.newsletter_info;
    this.initPage(this.fetchedData);
    this.initSupport(this.fetchedData);
    this.initNewsletter({ code, privacy_link, designers });
  },
  mounted() {
    this.iObserver = new IntersectionObserver(this.handleIntersection);
    this.iObserver.observe(this.$refs.stickySentinel);
    UtilsAnalytics.sendPromotionPushInitial();
    this.$nextTick(initCarousels);
    this.addRichRelevanceScripts();

  },
  unmounted() {
    this.iObserver.unobserve(this.$refs.stickySentinel);
  },
  methods: {
    ...mapActions('page', ['initPage']),
    ...mapActions('support', ['initSupport']),
    ...mapActions('newsletter', ['initNewsletter']),
    handleIntersection([entry]) {
      this.isStickySentinelVisible = entry.isIntersecting;
    },
    handleScroll(e) {
      const { direction } = e;
      this.scrollDirection = direction;

      if (this.isHome && typeof window !== 'undefined') {
        if (window.pageYOffset >= 1 && !this.isHeaderSticky) {
          const navBar = document.getElementById('nav-container');
          if (!navBar) return;
          navBar.classList.add('nav_sticky');
          this.isHeaderSticky = true;
        } else if (window.pageYOffset === 0 && this.isHeaderSticky) {
          const navBar = document.getElementById('nav-container');
          navBar.classList.remove('nav_sticky');
          this.isHeaderSticky = false;
        }
      }
    },
    addRichRelevanceScripts() {
        ScriptUtils.addScriptFromUrl(this.fetchedData.vendors?.RichRelevance?.scriptUrl);
    },
  },
};
</script>

<style></style>
