import newsletter from './modules/newsletter';
import page from './modules/page';
import cart from 'CommonStore/cart';

export default {
  modules: {
    newsletter: newsletter(),
    page: page(),
    cart: cart()
  }
};